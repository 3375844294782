
import { objectDifference } from "@/helpers";
import * as selectOptions from "@/helpers/selectOptions";
import {
  countyMapActions,
  countyMapGetters,
  countyMapMutations,
  countyMapState
} from "@/store/modules/county";
import * as types from "@/store/mutation-types";
import Vue from "vue";

import { countyForm } from "@/forms/admin/county";
import { FormBlock } from "@/components/FormBuilder/types";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";

export default Vue.extend({
  name: "read-notification",
  components: {
    CustomAlert
  },
  props: {},
  data() {
    return {
      error: "",
      tiers: selectOptions.tiers
    };
  },
  methods: {
    ...countyMapMutations({
      editField: types.SET_EDIT_FIELD,
      setEdit: types.SET_EDIT
    }),
    ...countyMapActions(["createCounty", "updateCounty"]),
    async editOrCreateCounty(): Promise<void> {
      try {
        if (this.isNew) {
          await this.createCounty(this.editing);
        } else {
          await this.updateCounty({
            id: this.editing._id,
            update: this.updatedFields
          });
        }
        this.setEdit({} as any);
        this.$emit("close");
        this.$appNotifySuccess(this.countySavedText);
      } catch (error) {
        this.error = error;
        this.$bugSnagClient.notify(error);
      }
    }
  },
  computed: {
    ...countyMapState(["editing", "makingApiRequest"]),
    ...countyMapGetters(["getCountyById"]),
    updatedFields(): any {
      const original = this.getCountyById(this.editing._id);
      const edited = this.editing;
      return objectDifference(edited, original);
    },
    isNew(): boolean {
      return !(this.editing && this.editing._id.length >= 12);
    },
    createOrEditCountyText(): string {
      if (!this.isNew) {
        return "Save Changes";
      } else {
        return "Create County";
      }
    },
    countySavedText(): string {
      if (this.editing && this.editing._id.length >= 12) {
        return "County updated";
      } else {
        return "County added";
      }
    },
    countyFields(): FormBlock[] {
      return countyForm;
    }
  }
});
