import Vue from "vue";
const { __getText } = Vue.prototype;
import { FormBlock } from "@/components/FormBuilder/types";
import {
  useFormSection,
  useFormFieldGroup,
  useTextField,
  useSelectField,
  useField
} from "@/components/FormBuilder/Helpers";
import * as selectOptions from "@/helpers/selectOptions";

export const countyForm: FormBlock[] = [
  ...useFormSection([
    ...useFormFieldGroup(
      [
        useTextField({
          key: "countyName",
          label: __getText("fields", "countyName"),
          placeholder: __getText("fields", "countyName"),
          required: true
        })
      ],
      { layout: "full" }
    ),
    ...useFormFieldGroup(
      [
        useTextField({
          key: "countyCode",
          label: __getText("fields", "countyCodeLabel"),
          placeholder: __getText("fields", "countyCodeLabel"),
          required: true
        })
      ],
      { layout: "full" }
    ),
    ...useFormFieldGroup(
      [
        useSelectField({
          key: "tier",
          label: __getText("fields", "tier"),
          options: selectOptions.tiers,
          required: true
        })
      ],
      { layout: "full" }
    ),
    ...useFormFieldGroup(
      [
        useField({
          type: "checkbox",
          key: "prohibitBinding",
          label: __getText("fields", "prohibitBinding")
        })
      ],
      { layout: "full" }
    )
  ])
];
